<template>
  <div class="page_content_wrap">
    <div class="household_expenses_wrap py-5">
      <div class="container">
        <div class="household_expenses_box" v-if="!isLoading">
          <h2 class="text-dark mb-2">
            What would you estimate your annual household expenses to be?
          </h2>
          <p class="text-dark mb-4">
            Use the sliders to estimate your expenses.
          </p>
          <form action="" @submit.prevent="submitWizard">
            <div
              class="form-group range_slider_wrap mb-5"
              :key="index"
              v-for="(expence, index) in expences"
            >
							<!-- <div class="row">
								<div class="col-sm-8">
									<input
										v-if="index == currentEdit"
										type="text"
										class="form-control"
										v-model="expence.title"
									/>
									<label class="text-dark m-0" v-if="index !== currentEdit">{{
										expence.title
									}}</label>
								</div>
								<div class="col-sm-4">
									<div class="d-flex justify-content-end align-items-center">
										<h4 class="m-0 text-primary mr-1">$</h4>
										<h4 class="m-0 text-primary d-flex align-items-center">
											<input
												type="number"
												class="form-control mr-1"
												placeholder="10000"
												min="0"
												max="500000"
												v-model="expence.value"
											/>
											<small class="text-dark font-size-sm"
												>/{{ expence.timeFrame }}</small
											>
										</h4>
									</div>
								</div>
							</div> -->

							<div class="row">
								<div class="col-sm-8">
									<label class="text-dark m-0" v-if="index !== currentEdit">{{
										expence.title
									}}</label>
								</div>
								<div class="col-sm-4">
									<div class="range_slider_input">
										<input
												type="number"
												class="form-control"
												placeholder="10000"
												min="0"
												max="500000"
												v-model="expence.value"
											/>
											<small class="range_slider_input_info"
												>/{{ expence.timeFrame }}</small
											>
									</div>
								</div>
							</div>
							
              <r-slider
                :min="0"
                :max="500000"
                :step="10"
                v-model.number="expence.value"
              ></r-slider>

              <!-- <div class="form_repeater_action">
                <a
                  href="javscript:void(0);"
                  class="border border-gray-300"
                  @click.prevent="enableEdit(index)"
                  ><i class="fal fa-edit"></i
                ></a>
              </div> -->
            </div>

            <div class="btn_continue_wrap">
              <button
                type="submit"
                class="btn btn-primary btn-lg btn-block mt-4"
                :disabled="isSubmitting"
                :class="{ 'btn-load': isSubmitting }"
              >
                CONTINUE <i class="fas fa-arrow-right pl-1"></i>
              </button>
            </div>
          </form>
        </div>

        <div class="loader" v-if="isLoading"></div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { getContact, updateContact } from "@/utils/firebase";
import { HUBSPOT_EXPENSES_LIST_KEY } from "@/utils/constants";
import { parse } from "query-string";

export default {
  name: "Screen11",
  data() {
    return {
      currentEdit: null,
      isLoading: true,
      isSubmitting: false,
      errors: [],
      enableAddNew: false,
      newIncomeTitle: "",
      hubspotQuestionId: HUBSPOT_EXPENSES_LIST_KEY,
    };
  },
  computed: {
    ...mapGetters("wizard", {
      expences: "getExpences",
      survey: "getSurvey",
    }),
  },
  mounted() {
    this.isLoading = true;

    // getContact([this.hubspotQuestionId])
    const contactId = "kyle@dataskeptic.com"
    getContact(contactId)
      .then((response) => {
        console.log({s11: response})
        let prop = response;//.data.properties;

        console.log("prop");
        console.log(prop);
        console.log(this.hubspotQuestionId);

        let expenses_list = prop[this.hubspotQuestionId]
          ? JSON.parse(prop[this.hubspotQuestionId])
          : [];

        const parsedData = expenses_list.length
          ? expenses_list
          : [{ title: "Expenses", timeFrame: "annual", value: 0 }];

        console.log("response.data.properties");
        console.log(parsedData);
        this.SET_EXPENCES(parsedData);

        this.isLoading = false;
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
        this.isLoading = false;
      });
  },
  methods: {
    ...mapActions("wizard", {
      updateProgress: "updateProgress",
    }),
    ...mapMutations("wizard", [
      "SET_EXPENCES",
      "ADD_NEW_EXPENCE",
      "REMOVE_EXPENCE",
    ]),
    enableEdit(index) {
      this.currentEdit = index;
    },
    removeExpence(obj) {
      this.REMOVE_EXPENCE(obj);
    },
    addNewIncome() {
      console.log(this.newIncomeTitle);
      if (this.newIncomeTitle != "") {
        this.ADD_NEW_EXPENCE({
          title: this.newIncomeTitle.replace(/\$+/g, ""),
          timeFrame: "annual",
          value: 0,
        });

        this.newIncomeTitle = "";
        this.enableAddNew = false;
      }
    },
    submitWizard(e) {
      this.isSubmitting = true;

      console.log("submitWizard");
      console.log(this.expences);

      if (this.expences.length) {
        this.expences[0].timeFrame = "annual";
      }
      const contactId = "kyle@dataskeptic.com"
      updateContact(contactId, { HUBSPOT_EXPENSES_LIST_KEY: JSON.stringify(this.expences) })
        .then((response) => {
          const queryParams = parse(location.search);

          if (queryParams["from-review"]) {
            this.$router.push({ name: "risk.review" });
          } else {
            let userObj = {
              riskLink: "risk.review",
            };
            this.updateProgress({ step: 1, data: userObj });
            this.$router.push({ name: "risk.review" });
          }
        })
        .catch((error) => {
          console.log(error);
          this.isSubmitting = false;
          alert("Something went wrong!");
        });

      e.preventDefault();
    },
  },
};
</script>
